<template>
	<button class="btn btn-light-primary font-weight-bold mr-2 buttonpointer" :disabled="!loaded" @click="$emit('button-click')">
		<span class="svg-icon svg-icon-lg">
			<inline-svg src="/media/svg/icons/Home/Clock.svg" :class="[ !loaded ? 'spinit' : '']"/>
		</span>
		{{ text }}
	</button>
</template>

<script>
export default {
	name: 'DateRangeButton',
	props: ['text','loaded'],
};
</script>

<style>
.buttonpointer {
	cursor: pointer;
}
@keyframes spin {
    0% { transform: rotate(0deg); }
    100% {  transform: rotate(359deg); }
}
.spinit {
    animation: spin 2s linear infinite;

}
</style>